<template>
	<v-row justify="center">
		<v-col cols="12"
			><h2 class="text-h4">
				Lista de pagos reportados por los regantes
			</h2></v-col
		>
		<v-col cols="12">
			<IrrigatorPaymentsReportedList />
		</v-col>
	</v-row>
</template>

<script>
	import IrrigatorPaymentsReportedList from '@/components/IrrigatorPaymentsReportedList';

	export default {
		name: 'irrigator-payments-reported',

		components: {
			IrrigatorPaymentsReportedList,
		},
	};
</script>
